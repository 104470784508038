import React, { useState } from "react";
import "./Milestone.scss";
import classname from "classnames";

// ICONS
import Crane from "../../../images/svg/arrastre-stevedoring-2.svg";
import Cargo from "../../../images/svg/cargo-delivery.svg";
import Warehouse from "../../../images/svg/warehouse.svg";
import Terminal from "../../../images/svg/terminal-management.svg";
import IT from "../../../images/svg/it-services.svg";
import Load from "../../../images/svg/loading.svg";

const MissionVission = () => {
  const [selected, setSelected] = useState(null);

  const milestones = [
      {
          id: 1,
          year: 1985,
          description: "MEIC Incorporated",
          position: 0,
          icon: Crane,
      },
      {
        id: 2,
        year: 2000,
        description: "Moved Operations to SBMA and Signed Long-Term Lease Agreement",
        position: 1,
        icon: Load,
      },
      {
        id: 3,
        year: 2004,
        description: "First Vessel received by sister company - MSTSI",
        position: 0,
        icon: Cargo,
      },
      {
        id: 4,
        year: 2013,
        description: "1M MTs Feed Wheat",
        position: 1,
        icon: Warehouse,
      },
      {
        id: 5,
        year: 2015,
        description: "Acquired first Modernized Unloader - 600 TPH Capacity",
        position: 0,
        icon: Load,
      },
      {
        id: 6,
        year: 2017,
        description: "Invested in Digital Transformation - Apollo/Accur8",
        position: 1,
        icon: IT,
      },
      {
        id: 7,
        year: 2018,
        description: "Informally launched the S.T.A.R. Group",
        position: 0,
        icon: Terminal,
      },
      {
        id: 8,
        year: 2020,
        description: "Acquired the Tagoloan Facility",
        position: 1,
        icon: Warehouse,
      },
      {
        id: 9,
        year: 2021,
        description: "Acquired the Bauan Facility",
        position: 0,
        icon: Warehouse,
      }
  ]

  return (
    <>
        <div className="milestone d-flex flex-column">
          <div className="header">
            <p>MILESTONE</p>
            <div className="reminder">
              Click the year to view description.
            </div>
          </div>
          <div className="d-flex flex-row each-con">
            { milestones.map((m, i) => 
              <div 
                className={classname({
                  "d-flex flex-row each": true,
                  "up": m.position === 0,
                  "down": m.position === 1,
                })} 
                key={i}
                onClick={() => setSelected(m.id)}
                >
                <img src={m.icon} alt="icon" width={50} />
                <div className="d-flex flex-column desc">
                  <p>{m.year}</p>
                  { m.id == selected ?
                    <span>{m.description}</span> : ""
                  }
                </div>
              </div>
            )}
          </div>

          <div className="milestone-line"></div>
        </div>
    </>
  );
};

export default MissionVission;