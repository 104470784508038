import { StaticImage } from "gatsby-plugin-image";
import React, { useEffect } from "react";
import "./History.scss";
import { BrowserView } from "react-device-detect";

const History = () => {
  return (
    <div className="history d-flex flex-row">
      <div className="d-flex flex-30">
        <BrowserView>
          <StaticImage
            src="../../../images/history.png"
            width={500}
            quality={100}
            formats={["AUTO", "WEBP", "AVIF"]}
            alt="about us"
            className="history-img"
          />
        </BrowserView>
      </div>
      <div className="flex-70 right d-flex flex-column history-text">
        {/* <p className="title">HISTORY</p> */}
        <div className="text">
          The S.T.A.R. Services Group is a group of companies that focuses on
          Integrated Supply Chain Services centering aroung Port Operations for
          Bulk and Break-Bulk Cargo and specifically catering to the
          Agricultural Industry of the Country. It has locations in Subic Bay,
          Pampanga, Batangas, Cagayan De Oro, among others.
          <br />
          <br />
          Subic Bay Freeport Grain Terminal Services Inc. (Subic Grain), the
          S.T.A.R. Group's flagship company was established in 2000 as a locator
          in the Subic Bay Freeport Zone (SBFZ). The Company's operating
          facilities were designed to handle both "HANDYMAX" and "PANAMAX"
          vessels for maximum economies of scale. Subic Grain is supported by
          its professionals with in-depth background in bulk handling operations
          with over 30 years in the port industry.
          <br />
          <br />
          Subic Bay Freeport Grain Terminal Services Inc. offers high-speed
          (est. 8,000 metric tonnes per day) bulk grain unloading services
          complemented by a 1,000 metric tonnes per hour, load/unload grain
          handling system and storage facilities with total capacity of over
          220,000 metric tonnes which translate to a grain througput capacity of
          some 1.5 million metric tonnes per year. Major facilities and
          equipment include 3x 180 MTPH rail-mounted mechanical/portal cranes,
          2x 800 KVA generator sets, 21,000 square meters of warehouse space. 15
          silos and 2 kilometer of conveyor systems. The Company has also
          retrofitted and extended its wharf to 160 Lineal Meters. Together with
          its sister company Mega Subic Terminal Services Inc. operating at the
          Naval Supply Depot (NSD Port), the group handles about 2.5 million
          metric tonnes of grain in the Port of Subic annually.
        </div>
      </div>
    </div>
  );
};

export default History;
