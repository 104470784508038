import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import "./MissionVission.scss";

const MissionVission = () => {
  return (
    <>
      <div className="mission-vission d-flex flex-column">
        <div className="d-flex flex-row mission">
          <div className="m-text flex-40 left desc d-flex flex-column">
            <p className="title">VISION</p>
              <div className="text">
                The S.T.A.R. Group's vision is to establish ourselves
                as the top of mind group in handling break-bulk cargo throughout
                the Philippine Archipelago while promoting socio-economic growth.
              </div>
          </div>

          <div className="m-img d-flex flex-50 right">
            <StaticImage
              src="../../../images/os-logistics.png"
              width={450}
              height={450}
              quality={100}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="about us"
            />
          </div>
        </div>

        <div className="d-flex flex-row vission" style={{ marginTop: `3em` }}>
          <div className="d-flex flex-50 right">
            <StaticImage
              src="../../../images/port-terminal.png"
              width={450}
              height={450}
              quality={100}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="about us"
            />
          </div>

          <div className="v-text flex-40 desc d-flex flex-column">
          <p className="title">MISSION</p>
            <div className="text">
            The S.T.A.R. Group's responsibility is to combine 
            internationally accepted, first class port operation 
            services,  and facilities with the best customer 
            relationship in order to provide the best service value 
            and performance for its clients, while helping its 
            stakeholders establish themselves as leaders of their 
            respective industries. 
            </div>
          </div>
        </div>
      </div>

      {/* <div className="mv-port-img"></div> */}
    </>
  );
};

export default MissionVission;
